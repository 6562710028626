import { FC, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Container, Typography, Link, Button, useTheme } from "@mui/material";
import { X as XIcon } from 'react-feather';
import { Google, Apple, Twitter, Facebook, Email } from '@mui/icons-material';
import { motion } from "framer-motion";

import { useLandingTrackingVar } from "../../vars/landing-tracking";
import { useLoginPush } from "../../hooks/login-push";
import { useAnalyticsQueued } from "../../hooks/delicious-analytics";
import { isMacLike } from "../../utils/detect";
import { TwitterButton, FacebookButton } from "../../views/auth/components";
import { Mark } from "../Mark";


export const Login: FC<{ fabRadius: string, fabCenterOffset: string }> = function Login({ fabRadius, fabCenterOffset }) {

  const theme = useTheme();
  const landingTracking = useLandingTrackingVar();
  const { track } = useAnalyticsQueued();
  const location = useLocation();
  const history = useHistory();

  const { setDisplayState, loginHint } = useLoginPush();

  const hide = () => {
    track('hide_notloggedin', { category: 'not-logged-in' });
    setDisplayState('hidden');
  }

  const goto = useCallback((path: string) => {
    history.push(`${path}?next=${encodeURIComponent(location.pathname+location.search)}`)
  } , [ history, location ]);

  return (
    <Container
      maxWidth='sm'
      sx={{
        position: 'fixed',
        bottom: 0,
        transform: 'translate(calc(50vw - 50%))',
        backgroundColor: 'secondary.main',
        color: 'secondary.contrastText',
        zIndex: 'bottomNav',
        p: 2,
        boxShadow: '0px 0px 10px 1px rgb(0 0 0 / 50%)',
        'body.auth-open &': { display: 'none' },

        '&.shake': {
          animation: 'shake 1s ease',
        },
      }}
      className={loginHint ? 'shake' : ''}

      component={motion.div}
      key="not-logged-in"
      initial={{ clipPath: `circle(${fabRadius} at ${fabCenterOffset} ${fabCenterOffset})`, display: 'block' }}
      animate={{ clipPath: `circle(2000px at ${fabCenterOffset} ${fabCenterOffset})`, display: 'block' }}
      exit={{ clipPath: `circle(${fabRadius} at ${fabCenterOffset} ${fabCenterOffset})`, transitionEnd: { display: "none" }, }}
      transition={{ duration: 0.2 }}
    >

      <style>
        {`
          @keyframes shake {
            0%, 100% { transform: translate(calc(50vw - 50%)); }
            10%, 30%, 50%, 70%, 90% { transform: translate(calc((50vw - 50%) - 10px)); }
            20%, 40%, 60%, 80% { transform: translate(calc((50vw - 50%) + 10px)); }
          }
        `}
      </style>

      {/* <Box onClick={hide} sx={{ position: 'absolute', top: 16, right: 16 }}>
        <XIcon style={{ color: theme.palette.secondary.lighter }} />
      </Box> */}

      <Typography
        variant="h1"
        align="center"
        sx={{ fontSize: '28px' }}
      >
        <Mark sx={{ fontWeight: 500, backgroundColor: 'secondary.light', color: 'primary.contrastText', borderRadius: '4px' }}>&nbsp;Join the 🍦 Club&nbsp;</Mark>
      </Typography>

      <Typography
        variant="body1"
        component="div"
        align="center"
        sx={{ mx: 2, my: 2 }}
      >
        {/* Create a free account or log in. Ice Cream Club is the best place to discover and share great things with your friends. */}
        Looking for recommendations from your friends? Your friends want your recommendations! Join the social network for culture.
      </Typography>

      {(landingTracking.source === 'twitter' || landingTracking.source === 'facebook' || landingTracking.probableInAppBrowser === 'facebook' || landingTracking.probableInAppBrowser === 'instagram') &&
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {landingTracking.source === 'twitter' &&
              <TwitterButton fullWidth={false} color="secondaryInverted">Sign up with Twitter</TwitterButton>
            }
            {(landingTracking.source === 'facebook' || landingTracking.probableInAppBrowser === 'facebook' || landingTracking.probableInAppBrowser === 'instagram') &&
              <FacebookButton fullWidth={false} color="secondaryInverted">Sign up with Facebook</FacebookButton>
            }
          </Box>

          <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Link onClick={() => goto('/auth/login')} color="#fff" fontWeight="500">Login</Link>
            &nbsp;or&nbsp;
            <Link onClick={() => goto('/auth/register')} color="#fff" fontWeight="500">sign up</Link>
            &nbsp;with&nbsp;
            {isMacLike && (
              <Apple fontSize='small' />
            )}
            <Google fontSize='small' />
            <Twitter fontSize='small' />
            <Facebook fontSize='small' />
            <Email fontSize='small' />
          </Typography>
        </>
      }

      {landingTracking.source !== 'twitter' && landingTracking.source !== 'facebook' && landingTracking.probableInAppBrowser !== 'facebook' && landingTracking.probableInAppBrowser !== 'instagram' &&
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={() => goto('/auth/register')} color="secondaryInverted" variant="contained" sx={{ mb: 1, minWidth: '160px' }}>Sign up</Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            Already a member?&nbsp;
            <Link onClick={() => goto('/auth/login')} variant="body1" color="#fff" fontWeight="500">Login</Link>
          </Box>
        </>
      }

    </Container>
  );
};
