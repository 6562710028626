import { useEffect, useCallback, useRef } from "react";
import { Alert, Typography, useTheme } from '@mui/material';
import { useSnackbar as useNotistack } from 'notistack';
import { useLocation } from "react-router-dom";
import { Capacitor } from "@capacitor/core";

export function useSnackbar() {

  const { enqueueSnackbar, closeSnackbar } = useNotistack();
  const theme = useTheme();

  const location = useLocation();
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      closeSnackbar();
    } else {
      mounted.current = true;
    }
    return () => closeSnackbar();
  }, [ location, closeSnackbar ]);

  const show = useCallback(({ text, severity, ...props }) => {
    const AlertProps = severity === 'error' ?{
      variant: 'outlined',
      sx: {
        backgroundColor: 'background.paper',
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        '& .MuiAlert-icon': { pt: '10px' },
        mt: Capacitor.getPlatform() === 'ios' ? 4 : 0
      }
    } : {
      variant: 'filled',
      sx: {
        backgroundColor: theme.palette.grey[700],
        color: '#fff',
        '& .MuiAlert-icon': { pt: '10px' },
        mt: Capacitor.getPlatform() === 'ios' ? 4 : 0
      }
    };
    enqueueSnackbar(text, {
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      content: (key, message) => (
        <Alert severity={severity || 'info'} key={key} {...AlertProps}>
          <Typography variant="body1">{message}</Typography>
        </Alert>
      ),
      ...props
    });
  }, [ enqueueSnackbar, theme ]);

  return { show, close: closeSnackbar };
}
