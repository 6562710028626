import PropTypes from 'prop-types';
import { styled } from '@mui/material';


export function Mark({ children, ...props }) {

  const Mark = styled('mark')``;

  return (
    <Mark {...props}>{children}</Mark>
  );
}

Mark.propTypes = {
  children: PropTypes.node,
};
