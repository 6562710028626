import { FC, useState, useCallback, SyntheticEvent } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Google } from '@mui/icons-material';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { GoogleAuthProvider } from 'firebase/auth';
import { Capacitor } from '@capacitor/core';
import { ButtonProps } from '@mui/material';

import { useAnalyticsQueued } from '@/hooks/delicious-analytics';
import { useOauth } from '../hooks/oauth';


type GoogleButtonProps = {
  forceRedirect?: boolean;
} & ButtonProps;


export const GoogleButton: FC<GoogleButtonProps> = function GoogleButton({ children, forceRedirect, ...props }) {

  const { track } = useAnalyticsQueued();
  const [ loading, setLoading ] = useState(false);
  const provider = new GoogleAuthProvider();
  const providerId = provider.providerId.replace('.', '_');

  const {
    signInWithCredential,
    signInWithRedirect,
    signInWithPopup,
    handlePopupError,
    preferRedirect
  } = useOauth({ provider, providerId });

  const redirect = useCallback(async () => {
    track(`${providerId}_oauth_start`, { category: 'auth', providerId, method: 'redirect' });
    signInWithRedirect();
    setLoading(false);
  }, [ signInWithRedirect, track, providerId ]);

  const popup = useCallback(async () => {
    track(`${providerId}_oauth_start`, { category: 'auth', providerId });
    setLoading(true);

    if (Capacitor.isNativePlatform()) {
      FirebaseAuthentication.signInWithGoogle()
        .then(result => {
          // translate to something the web env can use
          if(!result.credential?.idToken) {
            throw new Error('Google sign in failed, missing credential');
          }
          track(`${providerId}_oauth_success`, { category: 'auth', providerId, method: 'popup' });
          const credential = GoogleAuthProvider.credential(result.credential.idToken);
          signInWithCredential(credential).then(() => {
            track(`${providerId}_credential_success`, { category: 'auth', providerId, method: 'popup' })
          }).catch(err => {
            track(`${providerId}_credential_error`, { category: 'auth', providerId, method: 'popup', error: err.message })
          }).finally(() => setLoading(false));
        })
        .catch(err => {
          handlePopupError(err);
          setLoading(false);
        });
    } else {
      signInWithPopup().finally(() => setLoading(false));
    }
  }, [ track, handlePopupError, signInWithPopup, signInWithCredential, providerId ]);


  const handleClick = useCallback((ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    track(`${providerId}_oauth_click`, { category: 'auth', providerId });
    if(forceRedirect || (preferRedirect && forceRedirect !== false)) {
      redirect();
    } else {
      popup();
    }
  }, [ track, redirect, popup, providerId, preferRedirect, forceRedirect ]);


  return (
    <LoadingButton
      loading={loading}
      loadingPosition="start"
      variant="contained"
      color="primary"
      fullWidth
      data-cy='google-submit'
      onClick={handleClick}
      startIcon={<Google style={{ fontSize: '1.0rem'}} />}
      sx={{ mb: 1 }}
      {...props}
    >
      {children}
    </LoadingButton>
  );
}
