import { ElementType, FC } from 'react';
import { Button as MuiButton, useTheme, ButtonProps as MuiButtonProps } from '@mui/material';


type ButtonProps = MuiButtonProps & {
  component?: ElementType,
  shadowLevel?: number;
}


export const Button: FC<ButtonProps> = function Button({ children, sx={}, shadowLevel = 1, ...muiProps }) {

  const theme = useTheme();

  if(muiProps?.variant === 'text') {
    shadowLevel = 0;
  }
  if(muiProps.disableElevation) {
    shadowLevel = 0;
  }

  const style = {
    boxShadow: theme.shadows[shadowLevel],
    ...sx,
  };

  return (
    <MuiButton
      { ...muiProps }
      sx={style}
    >{children}</MuiButton>
  );
}
