import { memo } from 'react';
import { Tab, Tabs, AppBar, useTheme } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import { Home } from './icons/Home';
import { HomeSelected } from './icons/HomeSelected';
import { Explore } from './icons/Explore';
import { ExploreSelected } from './icons/ExploreSelected';
import { Share } from './icons/Share';
import { Saved } from './icons/Saved';
import { SavedSelected } from './icons/SavedSelected';
import { Friends } from './icons/Friends';
import { FriendsSelected } from './icons/FriendsSelected';
import { Kawaii } from './icons/Kawaii';


const BottomNav = memo(function BottomNav() {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const tabStyle = {
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 96,
    },
    '& svg': {
      height: 24,
      width: 24,
      marginBottom: '8px',
    },
    padding: '12px 12px 0 12px',
    fontSize: 10,
    paddingBottom: 0,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& .MuiTab-iconWrapper': {
      marginBottom: '8px',
    },
  };

  const topPath = location.pathname.split('/')[1];

  const tabLocations = [
    'feed',
    'explore',
    'share',
    'bookmarks',
    'groups',
  ]

  const selectedIcons = {
    'feed': <HomeSelected color={theme.palette.text.primary} />,
    'explore': <ExploreSelected color={theme.palette.text.primary} style={{ width: '28px', height: '24px' }} />,
    'bookmarks': <SavedSelected color={theme.palette.text.primary} />,
    'groups': <FriendsSelected color={theme.palette.text.primary} />,
  };

  let icons = {
    'feed': <Home color={theme.palette.text.primary} />,
    'explore': <Explore color={theme.palette.text.primary} style={{ width: '28px', height: '24px' }} />,
    'bookmarks': <Saved color={theme.palette.text.primary} />,
    'groups': <Friends color={theme.palette.text.primary} />,
  };

  icons[topPath] = selectedIcons[topPath];

  const handleChange = (event, newValue) => {
    icons[newValue] = selectedIcons[newValue];
    history.push('/' + newValue);
  };

  const handleClick = (ev) => {
    const newValue = ev.currentTarget.getAttribute('data-value');
    if(location.pathname.split('/')?.[2]) {
      history.push('/' + newValue);
    } else if(topPath === newValue) {
      document.querySelectorAll('.visible .scrollContainer').forEach(el => el.scroll({ top: 0, behavior: 'smooth' }))
    }
  }

  return (
    <AppBar
      component="nav"
      sx={{
        [`@media (min-width: ${theme.breakpoints.values['sm'] + 2 * (96 + 8)}px)`]: {
          '--width': '96px',
          '--gap': '8px',
          left: 0,
          top: 0,
          width: 'var(--width)',
          transform: `translate(calc(50vw - (${theme.breakpoints.values['sm']}${theme.breakpoints.unit}/2) - var(--width) - var(--gap)))`,
          boxShadow: 'none',
          '& .MuiTabs-flexContainer': {
            flexDirection: 'column',
          },
          '& .kawaii': {
            display: 'block',
          },
        },
        [`@media (max-width: ${theme.breakpoints.values['sm'] + 2 * (96 + 8)}px)`]: {
          translate: "0 0",
          'body.scroll-down &': {
            translate: "0 100%",
          },
        },

        position: 'fixed',
        bottom: -1, // extend outside to prevent half-pixel showing through under nav
        top: 'auto',
        right: 'auto',
        zIndex: 'bottomNav',
        maxWidth: theme.breakpoints.values.sm,
        transform: 'translateX(calc(50vw - 50%))',
        width: '100%',
        opacity: 1,
        transition: 'all .25s ease-in-out',
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px -1px 10px rgba(0, 0, 0, 0.12)',
        paddingBottom: 'calc(max(var(--sab, 18px) - 18px, 0px))',
        '& > .MuiBottomNavigationAction-root': {
          minWidth: 60,
        },
        'body.keyboard-open &, body.bottom-sheet-open &, body.share-open &, body.tour-open &, body.auth-open &, body.profile-open &, body.group-open &, body.fullscreen-video &': {
          opacity: 0,
          visibility: 'hidden',
          touchAction: 'none',
          pointerEvents: 'none',
        },

        '&& .Mui-selected': {
          color: theme.palette.text.primary,
        },
      }}
    >
      <Kawaii
        className='kawaii'
        onClick={() => history.push('/')}
        sx={{
          display: 'none',
          width: '48px',
          height: '48px',
          alignSelf: 'center',
          my: 2,
        }}
      />
      <Tabs value={ tabLocations.includes(topPath) ? topPath : false } onChange={handleChange} variant="fullWidth" sx={{ '& .MuiTabs-indicator': { display: 'none' } }}>
        <Tab label="Home" aria-label="home" value="feed" data-value='feed' icon={icons['feed']} sx={tabStyle} onClick={handleClick} />
        <Tab label="Saved" aria-label="saved" value="bookmarks" data-value='bookmarks' icon={icons['bookmarks']} sx={tabStyle} onClick={handleClick} />
        <Tab
          label='Share'
          aria-label="share"
          value="share"
          data-value='share'
          icon={
            <Share style={{ width: '32px', height: '32px', marginBottom: '4px' }} backgroundFill={theme.palette.primary.light} foregroundFill={theme.palette.primary.main} arrowStroke={theme.palette.primary.contrastText} />
          }
          sx={tabStyle}
          style={{
            paddingTop: '8px',
          }}
        />
        <Tab label="Explore" aria-label="explore" value="explore" data-value='explore' icon={icons['explore']} sx={tabStyle} onClick={handleClick} />
        <Tab label="Friends" aria-label="groups" value='groups' data-value='groups' icon={icons['groups']} sx={tabStyle} onClick={handleClick} />
      </Tabs>
    </AppBar>
  );
});

export default BottomNav;
