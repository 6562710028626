import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { NoSsr } from '@mui/material';

import { useSession } from '../hooks/auth';
import { relativize } from '../utils/url';
import { isServer } from '@/utils/detect';
import { PageTransition } from './PageTransition';


/* eslint-disable react/no-children-prop */
export function OffscreenRenderRoute({ component, path, restricted = false, transition, ...rest }) {

  const { auth, user, loading } = useSession();

  return (
    <Route
      path={path}
      children={({ match, location }) => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');
        searchParams.delete('token');
        const invitationToken = searchParams.get('invitation_token');
        searchParams.delete('invitation_token');
        const shortLink = searchParams.get('s');

        if(shortLink && !isServer) {
          // Do nothing until the short link is resolved
          return null;
        }

        let next = searchParams.get('next') ? relativize(searchParams.get('next')) : location.pathname+'?'+searchParams.toString();
        if(token) {
          next += `&token=${token}`;
        }
        if(invitationToken) {
          next += `&invitation_token=${invitationToken}`;
        }
        if(match) {
          if(restricted && !auth && !loading) {
            const redirect = `/auth/login?next=${encodeURIComponent(next)}`;
            console.log(`Redirecting from OffscreenRenderRoute to login`, { location, next, redirect });
            return (
              <NoSsr>
                <Redirect to={redirect} />
              </NoSsr>
            );
          }
        } else {
          if(restricted && (!auth || !user)) {
            return null;
          }
        }

        return <PageTransition match={match} component={component} transition={transition} />
      }}
      {...rest}
    />
  );
}

export default OffscreenRenderRoute;

OffscreenRenderRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  restricted: PropTypes.bool,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  transition: PropTypes.oneOf(['fade', 'slideBottom']),
};
