import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useLocation, useHistory, Redirect } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import loadable from '@loadable/component';

import { useSeenOnboarding } from './hooks/onboarding';
import { useSession } from './hooks/auth';
import { relativize } from './utils/url';
import { OffscreenRenderRoute } from './components/OffscreenRenderRoute';
import PrivateRoute from './components/PrivateRoute';
import { ResolveShortLink } from './components/ResolveShortLink';
import { Unsubscribe } from './views/unsubscribe';
import { ProfileRedirect } from './views/profile/ProfileRedirect';
import { AcceptInvitationRedirect } from './views/accept-invitation/AcceptInvitationRedirect';
import { VisibilityProvider } from './hooks/use-is-visible';
import { ResolveAppLink } from './components/ResolveAppLink';
const Index = loadable(() => import(/* webpackChunkName: "auth" */'./views/index'));
const Auth = loadable(() => import(/* webpackChunkName: "auth" */'./views/auth'));
const Profile = loadable(() => import(/* webpackChunkName: "profile" */'./views/profile'));
const Canonical = loadable(() => import(/* webpackChunkName: "canonical" */'./views/canonical'));
const Post = loadable(() => import(/* webpackChunkName: "canonical" */'./views/post'));
const Explore = loadable(() => import(/* webpackChunkName: "explore" */'./views/explore'));
const Search = loadable(() => import(/* webpackChunkName: "search" */'./views/search'));
const Feed = loadable(() => import(/* webpackChunkName: "private" */'./views/feed'));
const Share = loadable(() => import(/* webpackChunkName: "private" */'./views/share'));
const Collections = loadable(() => import(/* webpackChunkName: "collections" */'./views/collections'));
const Friends = loadable(() => import(/* webpackChunkName: "friends" */'./views/friends'));
const FriendsContainer = loadable(() => import(/* webpackChunkName: "friends" */'./views/profile/FriendsContainer'));
const Trending = loadable(() => import(/* webpackChunkName: "trending" */'./views/trending'));
const Professional = loadable(() => import(/* webpackChunkName: "professional" */'./views/professional'));
const Tour = loadable(() => import(/* webpackChunkName: "private" */'./views/tour'));
const Chat = loadable(() => import(/* webpackChunkName: "private" */'./views/chat'));
const Dev = loadable(() => import(/* webpackChunkName: "dev" */'./views/dev'));
// const AppLinksModal = loadable(() => import(/* webpackChunkName: "private" */'./views/app-links'));
const Siteindex = loadable(() => import(/* webpackChunkName: "siteindex" */'./views/siteindex'));


export function Routes() {

  const history = useHistory();
  const location = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const next = relativize(searchParams.get('next') || '/feed');

  const force = !!searchParams.get('force') || false;

  const { user } = useSession();

  useEffect(() => {
    console.log('location', location);
  }, [ location ]);


  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      import("capacitor-plugin-ios-webview-configurator").then(({ setWebviewBounce, setBackForwardNavigationGestures }) => {
        setWebviewBounce(false);
        setBackForwardNavigationGestures(true);
      });
    }
  }, []);

  const { seen } = useSeenOnboarding();
  useEffect(() => {
    const onbordingRedirectPaths = [/^\/feed/, /^\/explore/];
    const onbordingRedirectPathMatching = onbordingRedirectPaths.some(path => path.test(location.pathname));
    if (seen === false && user && onbordingRedirectPathMatching) {
      const next = searchParams.get('next') ? relativize(searchParams.get('next')) : encodeURIComponent(location.pathname+location.search);
      history.replace(`/tour?next=${next}`);
    }
  }, [ user, location, history, searchParams, seen ]);


  return (
    <>
      <ResolveShortLink />
      <ResolveAppLink />

      <OffscreenRenderRoute path="/feed" restricted={true} component={Feed} />
      <OffscreenRenderRoute path="/explore/:tab?" restricted={true} component={Explore} />
      <OffscreenRenderRoute path="/share" restricted={true} component={Share} />
      <OffscreenRenderRoute path="/bookmarks" exact restricted={true} component={Collections} />
      <OffscreenRenderRoute path='/groups' exact restricted={true} component={Friends} />
      <OffscreenRenderRoute path="/canonical/:canonicalId/:shareId?" restricted={false} component={Canonical} />
      <OffscreenRenderRoute path="/post/:shareId" restricted={false} component={Post} />

      <VisibilityProvider isVisible={true}>
        <Switch>
          <Route path="/accept-invitation">
            <AcceptInvitationRedirect />
          </Route>
          <PrivateRoute path='/unsubscribe'>
            <Unsubscribe />
          </PrivateRoute>
          <PrivateRoute path='/tour/:view?'>
            <Tour next={next} force={force} />
          </PrivateRoute>
          <Route path="/logout">
            <Redirect to="/auth/logout" />
          </Route>
          <Route path="/login">
            <Redirect to={`/auth/login?next=${next}`} />
          </Route>
          <Route path="/auth/:action">
            <Auth />
          </Route>
          <Route exact path='/'>
            <Index />
          </Route>
          <Route path="/trending">
            <Trending />
          </Route>
          <Route path="/profile-id/:contactId/:tab?/:id?">
            <ProfileRedirect />
          </Route>
          <Route path="/profile/friends/:tab?">
            <FriendsContainer />
          </Route>
          <Route path="/profile/:tab?/:id?">
            <Profile />
          </Route>
          <Route path="/@:username/collections/:id">
            <Collections isVisible={true} />
          </Route>
          <PrivateRoute path="/bookmarks/:tab/:id?">
            <Collections isVisible={true} />
          </PrivateRoute>
          <Route path="/@:username/friends/:tab?">
            <FriendsContainer />
          </Route>
          <Route path="/@:username/:tab?/:id?">
            <Profile />
          </Route>
          <PrivateRoute path="/chat">
              <Chat />
          </PrivateRoute>
          <PrivateRoute path="/groups/:id/:tab?">
            <Friends />
          </PrivateRoute>
          <Route path="/public">
            <Redirect to='/feed' />
          </Route>
          <Route path="/search">
            <Search />
          </Route>
          <Route path="/professional/:id">
            <Professional />
          </Route>
          <Route path="/dev">
            <Dev />
          </Route>
          <Route path="/siteindex/">
            <Siteindex />
          </Route>

          <Redirect exact strict from="/canonical/:id" to={{ pathname: '/canonical/:id/', search: location.search }} />
          <Redirect exact strict from="/post/:id" to={{ pathname: '/post/:id/', search: location.search }} />


          {/* The block below is repeating the OffscreenRenderRoutes above with no content and is used for fallback redirect in Switch */}
          <Route path="/feed"></Route>
          <Route path="/explore/:tab?"></Route>
          <Route path="/share"></Route>
          <Route path='/bookmarks'></Route>
          <Route path='/groups'></Route>
          <Route path="/canonical/:canonicalId/:shareId?"></Route>
          <Route path="/post/:shareId"></Route>
          <Route path='*'>
            <Redirect to={`/auth/login?next=${next}`} />
          </Route>

        </Switch>
      </VisibilityProvider>


      {/* Don't show before logging in */}
      {/* {user &&
        <Suspense fallback={null}>
          <AppLinksModal />
        </Suspense>
      } */}
    </>
  );
}

Routes.propTypes = {
  next: PropTypes.string,
};
