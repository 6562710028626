import { gql } from '@apollo/client';


export const CanonicalFragment = gql`
  fragment CanonicalFragment on CanonicalContent {
    _id
    type
    rating {
      _id
      rating
      person {
        _id
      }
    }
    createdAt
    tvshow {
      _id
      title
      overview
      startYear
      endYear
      genres
      tmdb
      imdb
      imdbRating
      imdbVotes
      homepage
      firstAirDate
      numberOfSeasons
      directors {
        _id
        name
        professional
        tmdb
      }
      createdBy {
        _id
        name
        professional
        tmdb
      }
      networks {
        name
        logo {
          hash
        }
      }
      poster {
        hash
        blurhash
        width
        height
        alt
      }
      backdrop {
        hash
        blurhash
        width
        height
        alt
      }
      video {
        site
        key
        type
        official
        title
      }
      seasons {
        seasonNumber
        name
        overview
        airDate
        episodeCount
        poster {
          hash
          blurhash
          width
          height
          alt
        }
      }
    }
    movie {
      _id
      title
      overview
      year
      genres
      tmdb
      imdb
      imdbRating
      imdbVotes
      homepage
      release
      directors {
        _id
        name
        professional
        tmdb
      }
      poster {
        hash
        blurhash
        width
        height
        alt
      }
      backdrop {
        hash
        blurhash
        width
        height
        alt
      }
      video {
        site
        key
        type
        official
        title
      }
    }
  }
`;


export const CanonicalProvidersFragment = gql`
  fragment CanonicalProvidersFragment on CanonicalContent {
    movie {
      _id
      providers {
        type
        displayPriority
        provider {
          _id
          name
          color
          logo {
            hash
          }
        }
      }
      providersCountry
    }
    tvshow {
      _id
      providers {
        type
        displayPriority
        provider {
          _id
          name
          color
          logo {
            hash
          }
        }
      }
      providersCountry
    }
  }
`;
