import { gql } from '@apollo/client';
import { CanonicalFragment, CanonicalProvidersFragment } from './canonicalFragment';
import { ListFragment } from './listFragment';
import { ShareFragment } from './shareFragment';
import { CommentFragment } from './commentFragment';


export const GET_WATCHLIST_PAGE_SIZE = 24;
export const GET_LIST_ITEMS_PAGE_SIZE = 24;
export const GET_FRIENDS_ACTIVITY_PAGE_SIZE = 24;


export const GET_LISTS = gql`
  query GetLists {
    currentUser {
      _id
      lists {
        ...ListFragment
        items {
          _id
          createdAt
          share {
            _id
          }
          canonicalContent {
            _id
          }
        }
      }
      followedLists {
        ...ListFragment
        items {
          _id
          createdAt
          share {
            _id
          }
          canonicalContent {
            _id
          }
        }
      }
    }
  }
  ${ListFragment}
`;


export const GET_LIST = gql`
  query GetList($id: ID!) {
    getList(id: $id) {
      ...ListFragment
      followedBy {
        _id
        username
        avatar {
          url
        }
      }
      moreLists {
        ...ListFragment
      }
      moreShares {
        ...ShareFragment
        canonicalContent {
          ...CanonicalFragment
        }
      }
      comments {
        ...CommentFragment
      }
    }
  }
  ${ShareFragment}
  ${ListFragment}
  ${CanonicalFragment}
  ${CommentFragment}
`;


export const GET_LIST_ITEMS = gql`
  query GetListItems($id: ID!, $first: Int, $after: String) {
    getList(id: $id) {
      _id
      listItems(first: $first, after: $after) {
        edges {
          cursor
          node {
            _id
            createdAt
            share {
              ...ShareFragment
              watchState {
                watchedBy
                watchlistBy
              }
              canonicalContent {
                ...CanonicalFragment
              }
            }
            canonicalContent {
              ...CanonicalFragment
              watchState {
                watchedBy
                watchlistBy
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  }
  ${ShareFragment}
  ${CanonicalFragment}
`;


export const GET_WATCHLIST = gql`
  query GetWatchlist($username: String!, $first: Int, $after: String) {
    profile(username: $username) {
      _id
      watchlist(first: $first, after: $after) {
        edges {
          cursor
          node {
            _id
            deleted
            share {
              ...ShareFragment
              watchState {
                watchedBy
                watchlistBy
              }
            }
            canonicalContent {
              ...CanonicalFragment
              ...CanonicalProvidersFragment
              watchState {
                watchedBy
                watchlistBy
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  }
  ${ShareFragment}
  ${CanonicalFragment}
  ${CanonicalProvidersFragment}
`;


export const GET_WATCHED_PAGE_SIZE = 16;

export const GET_WATCHED = gql`
  query GetWatched($username: String!, $first: Int, $after: String) {
    profile(username: $username) {
      _id
      watched(first: $first, after: $after) {
        edges {
          cursor
          node {
            _id
            deleted
            share {
              ...ShareFragment
              watchState {
                watchedBy
                watchlistBy
              }
            }
            canonicalContent {
              ...CanonicalFragment
              watchState {
                watchedBy
                watchlistBy
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  }
  ${ShareFragment}
  ${CanonicalFragment}
`;


export const GET_LIST_RECOMMENDATIONS = gql`
  query GetListRecommendations($input: ListRecommendationsInput!) {
    getListRecommendations(input: $input) {
      ...ListFragment
    }
  }
  ${ShareFragment}
  ${ListFragment}
  ${CanonicalFragment}
`;


export const GET_POPULAR_LISTS = gql`
  query GetPopularLists($input: PopularListsInput!) {
    getPopularLists(input: $input) {
      ...ListFragment
    }
  }
  ${ListFragment}
`;


export const GET_LIST_INBOX_PAGE_SIZE = 16;

export const GET_LIST_INBOX = gql`
  query GetListInbox($first: Int, $after: String) {
    getListInbox(first: $first, after: $after, canonicalContent: true) { # canonicalContent flag is for supporting old versions of the app
      edges {
        cursor
        node {
          _id
          createdAt
          share {
            ...ShareFragment
            watchState {
              watchedBy
              watchlistBy
            }
          }
          canonicalContent {
            ...CanonicalFragment
            watchState {
              watchedBy
              watchlistBy
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
  ${ShareFragment}
  ${CanonicalFragment}
`;

export const CREATE_LIST = gql`
  mutation CreateList($input: CreateListInput!) {
    createList(input: $input) {
      ...ListFragment
    }
  }
  ${ListFragment}
`;

export const DELETE_LIST = gql`
  mutation DeleteList($listId: ID!, $restore: Boolean) {
    deleteList(listId: $listId, restore: $restore) {
      _id
    }
  }
`;

export const UPDATE_LIST = gql`
  mutation UpdateList($input: UpdateListInput!) {
    updateList(input: $input) {
      ...ListFragment
    }
  }
  ${ListFragment}
`;


export const FOLLOW_LIST = gql`
  mutation FollowList($listId: ID!, $follow: Boolean!) {
    followList(listId: $listId, follow: $follow) {
      _id
    }
  }
`;


export const GET_LIST_RANDOM_ITEM = gql`
  query GetListRandomItem($listId: ID!) {
    getList(id: $listId) {
      _id
      randomItem {
        edges {
          cursor
          node {
            _id
            createdAt
            share {
              ...ShareFragment
              watchState {
                watchedBy
                watchlistBy
              }
              canonicalContent {
                ...CanonicalFragment
              }
            }
            canonicalContent {
              ...CanonicalFragment
              watchState {
                watchedBy
                watchlistBy
              }
            }
          }
        }
      }
    }
  }
  ${ShareFragment}
  ${CanonicalFragment}
`;


export const FRIENDS_ACTIVITY = gql`
  query GetFriendsActivity($first: Int, $after: String, $filter: TrendingFilterInput!) {
    friendsActivity(first: $first, after: $after, filter: $filter) {
      edges {
        cursor
        node {
          _id
          deleted
          share {
            ...ShareFragment
            watchState {
              watchedBy
              watchlistBy
            }
          }
          canonicalContent {
            ...CanonicalFragment
            ...CanonicalProvidersFragment
            watchState {
              watchedBy
              watchlistBy
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
  ${ShareFragment}
  ${CanonicalFragment}
  ${CanonicalProvidersFragment}
`;
