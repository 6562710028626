import { ComponentType, createElement, FC, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { VisibilityProvider } from '../hooks/use-is-visible';


type TransitionState = 'visible' | 'hidden' | 'willAppear' | 'willDisappear';

const duration = 0.25;

const transitionClasses: Record<TransitionState, object> = {
  visible: {
    opacity: 1,
    pointerEvents: 'all',
    visibility: 'visible',
    contentVisibility: 'visible',
    transform: 'scaleX(1)',
    transition: 'unset',
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
    visibility: 'hidden',
    contentVisibility: 'hidden',
    transform: 'scaleX(0)',
    transition: 'unset',
  },
  willAppear: {
    opacity: 1,
    pointerEvents: 'none',
    visibility: 'visible',
    contentVisibility: 'visible',
    transform: 'scaleX(1)',
    transition: `opacity ${duration/2}s ease-in-out ${duration/2}s`,
  },
  willDisappear: {
    opacity: 0,
    pointerEvents: 'none',
    visibility: 'visible',
    contentVisibility: 'visible',
    transform: 'scaleX(1)',
    transition: `opacity ${duration/2}s ease-in-out 0s`,
  },
};

const css = Object.fromEntries(Object.entries(transitionClasses).map(([className, styles]) => [`&.${className}`, styles]));

const sx = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  ...css,
};


export const PageTransition: FC<{ match: boolean, component: ComponentType<{ isVisible: boolean, willAppear: boolean, willDisappear: boolean }> }> = function PageTransition({ match, component }) {
  const nodeRef = useRef(null);
  const history = useHistory();

  const [animationState, setAnimationState] = useState<TransitionState>(match ? 'visible' : 'hidden');
  const animationTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if(match) {
      if(animationState === 'hidden') {
        if(history.action === 'POP') {
          setAnimationState('visible');
        } else {
          setAnimationState('willAppear');
          setTimeout(() => {
            setAnimationState('visible');
          }, duration*1000);
        }
      } else if(animationState === 'willDisappear') {
        if(animationTimeoutRef.current) {
          clearTimeout(animationTimeoutRef.current);
        }
        setAnimationState('visible');
      } else if(animationState === 'willAppear') {
        // do nothing
      } else if(animationState === 'visible') {
        // do nothing
      } else {
        throw new Error(`Unexpected animation state: ${animationState}, match: ${match}`);
      }
    } else {
      if(animationState === 'visible') {
        if(history.action === 'POP') {
          setAnimationState('hidden');
        } else {
          setAnimationState('willDisappear');
          animationTimeoutRef.current = setTimeout(() => {
            setAnimationState('hidden');
          }, duration*1000);
        }
      } else if(animationState === 'willAppear') {
        if(animationTimeoutRef.current) {
          clearTimeout(animationTimeoutRef.current);
        }
        setAnimationState('hidden');
      } else if(animationState === 'willDisappear') {
        // do nothing
      } else if(animationState === 'hidden') {
        // do nothing
      } else {
        throw new Error(`Unexpected animation state: ${animationState}, match: ${match}`);
      }
    }
  }, [match, history.action, animationState]);

  const isVisible = animationState === 'visible';

  return (
    <Box
      ref={nodeRef}
      className={clsx({ [animationState]: true })}
      sx={sx}
    >
      <VisibilityProvider isVisible={isVisible}>
        <Box sx={{
          height: '100%',
          '& textarea, & input': {
            visibility: isVisible ? 'inherit' : 'hidden',
            contentVisibility: isVisible ? 'inherit' : 'hidden',
          }
        }}>
          {createElement(component, { isVisible: isVisible, willAppear: animationState === 'willAppear', willDisappear: animationState === 'willDisappear' })}
        </Box>
      </VisibilityProvider>
    </Box>
  );
}
